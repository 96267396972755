<template>
<Page id="tips-view-questions" title="Visar frågor">
      <QTable></QTable>
</Page>
</template>

<script>
const Page = () => import("@/components/common/Page");
const QTable = () => import("@/components/tips/view/questions/QuestionsTable");

export default {
      name: "ViewQuestions",
      components: {
            Page,
            QTable
      },
      metaInfo: {
            title: "Visa Frågor",
            meta: [{
                  name: "description",
                  content: "Här är alla frågor för turneringen",
            }, ],
      },
      mounted() {
            this.$store.dispatch("tipsg/getViewQuestionsTips");
            this.$store.dispatch("tournamentsg/getTournamentUsers");
      },
};
</script>
